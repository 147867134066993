import React from "react";
import Wrapper from "../Forms/Wrapper";
import HeaderText from "../Forms/HeaderText";
import CenterSide from "../Forms/CenterSide";
import Card from "../Forms/Card";
import imgTerminalSolutions from "../../assets/img/Integra-TAS.svg";
import imgCatalyst from "../../assets/img/Catalyst.svg";
import catalystLogo from "../../assets/img/CATALIST-logo.svg";
import integraLogo from "../../assets/img/INTEGRA-logo.svg";

export default function Services() {
    const descTerminalSolutions = "Gate Acess Control Module, Product Loading and Unloading, Weighing System, Tank Management";
    const descCatalyst = "Industrial IOT, Embedded System, Edge Computing, Wireless Sensor, Fleet Gateway, Pipeline Monitoring";

    return (
        <Wrapper id="services" className="container">
            <CenterSide>
                <div className="row">
                    <HeaderText className="col-12 regular fontAbel">Services</HeaderText>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-12">
                        <Card title="Terminal Solutions" imageUrl={imgTerminalSolutions} description={descTerminalSolutions} altImg="Integra" iconUrl={integraLogo} />
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 mt-sm">
                        <Card title="Integration System" imageUrl={imgCatalyst} description={descCatalyst} altImg="Catalyst" iconUrl={catalystLogo} />
                    </div>
                </div>
            </CenterSide>
        </Wrapper>
    );
}
