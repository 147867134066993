import styled from "styled-components"; 

const CenterSide = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  @media (max-height: 1200px){
    min-height:668px;
  }
  @media (max-height: 960px){
    min-height:468px;
  }
}
`;

export default  CenterSide;