import React from "react";
import Button from "../Buttons/Button";
import Wrapper from "../Forms/Wrapper";
import CenterSide from "../Forms/CenterSide";
import CircleBulletList from "../Generals/CircleBulletList";
import HeadlineText from "../Forms/HeadlineText";
import CaptionText from "../Forms/CaptionText";
export default function Header(){
    return (
        <Wrapper id="home" className="flexCenter darkColor">
            <CenterSide className="flexColumn">                
                <CaptionText className="regular fontAbel darkColor">
                    Trusted partner for
                </CaptionText>
                <HeadlineText className="extraBold fontRoboto">
                  Bridging Ideas into Reality
                </HeadlineText>
                <CaptionText className="regular fontAbel flexCenter darkColor">
                    Digitalizing Visions, Transforming Realities: Empowering Industries with Excelence in Interated Digital Solutions for Power Plants, Oil & Gas and Smart Manufacturing
                </CaptionText>
              <CircleBulletList className="flexCenter">
                <ul>
                  <li className="font16 regular fontAbel">Management System</li>
                  <li className="font16 regular fontAbel">Control System</li>
                  <li className="font16 regular fontAbel">SCADA</li>
                  <li className="font16 regular fontAbel">PLC/ DCS</li>
                  <li className="font16 regular fontAbel">IT/OT Convergance</li>
                  <li className="font16 regular fontAbel">Industrial IoT</li>
                </ul>
              </CircleBulletList>
              <div className="flexCenter fontInter">
              <Button label="Learn More"  bgColor="#00A5B1" hoverColor="#077f87"/>
              </div>
            </CenterSide>
        </Wrapper>
    );
}