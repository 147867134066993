import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import citiaLogo from "../../assets/img/FA_CITIA_ENGINEERING-02.png";
import ImgIcon from "../../assets/svg/Services/ImgIcon";

export default function Sitebar({ sitebarOpen, toggleSitebar }) {
  return (
    <Wrapper className="animate toscaBg" sitebarOpen={sitebarOpen}>
      <SitebarHeader className="flexSpaceCenter">
        <div className="flexCenter">
        <ImgIcon src={citiaLogo} className="d-inline-block align-top" />
        </div>
        <CloseBtn onClick={() => toggleSitebar(!sitebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SitebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="regular font15 pointer Inter">
          <Link
            onClick={() => toggleSitebar(!sitebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="home"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Home
          </Link>
        </li>
        
        <li className="regular font15 pointer Inter">
          <Link
            onClick={() => toggleSitebar(!sitebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="aboutUs"
            spy={true}
            smooth={true}
            offset={-60}
          >
            About Us
          </Link>
        </li>
        <li className="regular font15 pointer Inter">
          <Link
            onClick={() => toggleSitebar(!sitebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="services"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Services
          </Link>
        </li>
        <li className="regular font15 pointer Inter">
          <Link
            onClick={() => toggleSitebar(!sitebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="projects"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Projects
          </Link>
        </li>
      </UlStyle>
      <UlStyle className="flexCenter">
        {/* <li className="regular font15 pointer Inter">
          <a href="/" style={{ padding: "10px 30px 10px 0" }} className="whiteColor">
            Log in
          </a>
        </li> */}
        <li className="semiBold font15 pointer flexCenter">
          <a href="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
            Contact Us
          </a>
        </li>
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 500px;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sitebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SitebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 20px;
  li {
    margin: 20px 0;
  }
`;
