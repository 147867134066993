import React from 'react';
import styled from 'styled-components';

const   StyledButton = styled.button`
  padding: 10px 20px;
  color: ${({ textColor }) => textColor || '#818285'}; /* Text color, default: #818285 */
  background-color: transparent;
  border: 1px solid ${({ borderColor }) => borderColor || '#818285'}; /* Border color, default: #818285 */
  border-radius: 30px; /* Border-radius for rounded corners */
  cursor: pointer;

  &:hover {
    background-color: ${({ hoverColor }) => hoverColor || '#818285'}; /* Hover background color, default: #818285 */
    color: #fff; /* Hover text color */
  }
`;

const OutLineButton = ({ label, onClick, textColor, borderColor, hoverColor }) => {
  return (
    <StyledButton
      onClick={onClick}
      textColor={textColor}
      borderColor={borderColor}
      hoverColor={hoverColor}
    >
      {label}
    </StyledButton>
  );
};

export default OutLineButton;
