
import styled from "styled-components"; 

const ImgIcon = styled.img`
    width: 150px;
    height: 60px;
    @media (max-width:400px){
        width: 120px;
        height: 40px;
    }
`;

export default ImgIcon;