import styled from "styled-components";

const Paragraph = styled.p`
    margin-top:80px;
    text-align:justify;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    @media (max-width:600px){
        text-align:justify;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
    }

    ${({ className }) => (className ? `className: ${className};` : '')}
`;

export default Paragraph;