import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import Sitebar from "./SiteBar";
import citiaLogo from "../../assets/img/citialogo.png";
import OutLineButton from "../Buttons/OutlineButton";
import { FaBars} from "react-icons/fa6";

//
import ImgIcon from "../../assets/svg/Services/ImgIcon";
export default function TopNavbar() 
{
    const [y, setY] = useState(window.scrollY);
    const [sitebarOpen, toggleSitebar] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => setY(window.scrollY));
        return () => {
        window.removeEventListener("scroll", () => setY(window.scrollY));
        };
    }, [y]);

    return (
        <>
         <Sitebar sitebarOpen={sitebarOpen} toggleSitebar={toggleSitebar} />
          <Wrapper className="flexCenter animate whiteBg" style={y > 400 ? { height: "60px" } : { height: "80px" }}>
              <NavInner className="container flexSpaceCenter">
                <Link className="pointer flexNullCenter" to="home" smooth={true}>
                <ImgIcon src={citiaLogo} className="d-inline-block align-top" />
                </Link>
                <BurderWrapper className="pointer" onClick={() => toggleSitebar(!sitebarOpen)}>
                  <FaBars className="toscaColor"  style={{height:"25px", width:"25px"}}/>
                </BurderWrapper>
                <UlWrapper className="flexNullCenter">
                  <li className="regular font15 pointer Inter">
                    <Link activeClass="active" style={{ padding: "10px 15px" }} to="home" spy={true} smooth={true} offset={-80}>
                      Home
                    </Link>
                  </li>
                  <li className="regular font15 pointer Inter">
                    <Link activeClass="active" style={{ padding: "10px 15px" }} to="aboutUs" spy={true} smooth={true} offset={-80}>
                      About Us
                    </Link>
                  </li>
                  <li className="regular font15 pointer Inter">
                    <Link activeClass="active" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80}>
                      Services
                    </Link>
                  </li>
                  <li className="regular font15 pointer Inter">
                    <Link activeClass="active" style={{ padding: "10px 15px" }} to="projects" spy={true} smooth={true} offset={-80}>
                      Projects
                    </Link>
                  </li>
                </UlWrapper>
                <UlWrapperRight className="flexNullCenter">
                  <li className="regular font15 Inter pointer flexCenter ">
                    <OutLineButton href="/" textColor='#818285' label="Contact" className="font15 Inter" style={{ padding: "10px 15px" }}/>
                  </li>
                </UlWrapperRight>
              </NavInner>
            </Wrapper>
    </>
  );
}
//

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;