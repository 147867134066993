import React from "react";
import Wrapper from "../Forms/Wrapper";
import CenterSide from "../Forms/CenterSide";
import HeaderText from "../Forms/HeaderText";
import Paragraph from "../Forms/Paragraph";
import "./AboutUs.css";

export default function Services() {

    return (
        <div className="bgAbout">
            <Wrapper id="aboutUs" className="flexCenter">
                <CenterSide className="flexColumn">
                    <HeaderText className="regular fontAbel lightColor"> About Us </HeaderText>
                    <Paragraph className="regular fontAsap lightColor">PT Citia Engineering Indonesia specializes in integrating systems for automation and Industrial Internet of Things (IIoT) solutions. We collaborate with numerous industries, leveraging our knowledge and experience to achieve project objectives and enhance plant performance. With expertise in PLC, DCS, SCADA, IIoT, and Instrumentation systems, we offer in-depth knowledge and experience in these technologies. Based in Bandung, we provide services that cover the entire nation of Indonesia.</Paragraph>
                </CenterSide>
            </Wrapper>
        </div>
    );
}


