import React from 'react';
import './Card.css'; // Import CSS file for styling

const Card = ({ imageUrl, title, description, altImg, iconUrl }) => {
    if (iconUrl == null){
        return (
            <div className="card shadow">
                <img src={imageUrl} alt={altImg} className="cardImage" />
                <div className="overlay">
                    <div className="content">
                        <p className="cardDescription fontAsap regular font12 mt-1">{description}</p>
                        <h2 className="cardTitle fontAbel regular">{title}</h2>
                    </div>
                </div>
            </div>
        );
    }
    else
    {
        return (
            <div className="card shadow">
                <img src={imageUrl} alt={altImg} className="cardImage" />
                <div className="overlay">
                    <div className="content">
                        <p className="cardDescription fontAsap regular font12 mt-1">{description}</p>
                        <h2 className="cardTitle fontAbel regular mt-1">{title}</h2>
                        <img className="icon" src={iconUrl} />
                    </div>
                </div>
            </div>
        );
    }
    
};

export default Card;
