import styled from "styled-components";


const CaptionText = styled.h4`
    margin-top : 10px;
    margin-bottom : 10px;
    font-size: 16px;
    text-align: center;
    max-width : 720px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width:600px){
        text-align:center;
        margin-left: auto;
        margin-right: auto;
        font-size: 12px;
    }

    ${({ className }) => (className ? `className: ${className};` : '')}
`;

export default CaptionText;