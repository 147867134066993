import './App.css';
import Landing from './screens/Landing';
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
    <Helmet>
      <link rel="stylesheet" href="https://rsms.me/inter/inter.css"/>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Abel:wght@400;700&display=swap" />
    </Helmet>
    <Landing/>
    </>
  );
}

export default App;
