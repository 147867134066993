import styled from "styled-components";

const HeaderText = styled.h5`
    margin-top : 1rem;
    margin-bottom : 1rem;
    text-align: center;
    max-width : 720px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width:600px){
        text-align:center;
        margin-left: auto;
        margin-right: auto;
    }

    ${({ className }) => (className ? `className: ${className};` : '')}
`;

export default HeaderText;