import React from 'react';
import styled from 'styled-components';

const   StyledButton = styled.button`
    padding: 10px 20px;
    color: #fff; /* Text color */
    background-color: ${({ bgColor }) => bgColor || '#818285'}; /* Background color, default: #818285 */
    border: none; /* No border for an outline-less button */
    border-radius: 30px; /* Border-radius for rounded corners */
    cursor: pointer;

    &:hover {
    background-color: ${({ hoverColor }) => hoverColor || '#818285'}; /* Hover background color, default: #818285 */
    }
`;

const Button = ({ label, onClick, bgColor, hoverColor}) => {
    return (
      <StyledButton onClick={onClick} bgColor={bgColor} hoverColor={hoverColor}>
        {label}
      </StyledButton>
    );
  };
  
  export default Button;