import React from "react";
import Wrapper from "../Forms/Wrapper";
import HeaderText from "../Forms/HeaderText";
import CenterSide from "../Forms/CenterSide";
import Card from "../Forms/Card";
import imgTAS from "../../assets/img/TAS.svg";
import imgWaterLevel from "../../assets/img/Water Level.svg";
import imgSmartMT from "../../assets/img/Smart-MT.svg";

export default function Projects() {
    const descTAS = "A crucial role in optimizing terminal operations, boosting efficiency, and enhancing customer service.";
    const descWaterLevel = "System designed to monitor real-time data regarding water level from the river. ";
    const descSmartMT = "Solutions for fleet monitoring, designed to optimizing safety and eficiency";
    return (
        <Wrapper id="projects">
            <CenterSide>
                <div className="row">
                    <HeaderText className="regular fontAbel">Projects</HeaderText>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-12">
                        <Card title="Terminal Automation System (TAS)" imageUrl={imgTAS} description={descTAS} altImg="TAS" />
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 mt-sm">
                        <Card title="Water Level Telemetering" imageUrl={imgWaterLevel} description={descWaterLevel} altImg="Water Level Telemetering" />
                    </div>
                </div>
            </CenterSide>
        </Wrapper>
    );
}
//

