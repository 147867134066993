import styled from "styled-components";


const HeadlineText = styled.h1`
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 72px;
    text-align: center;
    max-width : 720px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width:400px){
        font-size:53px;
    }
    ${({ className }) => (className ? `className: ${className};` : '')}
`;

export default HeadlineText;