import styled from "styled-components";

const Wrapper = styled.section`
  margin-top:80px;
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
  }
  @media (max-height: 1200px){
    min-height:868px;
  }
  @media (max-height: 960px){
    min-height:568px;
  }

  
  /* Add id and className from props */
  ${({ id }) => (id ? `id: ${id};` : '')}
  ${({ className }) => (className ? `className: ${className};` : '')}
`;

export default Wrapper;