import styled from "styled-components";

const CircleBulletList = styled.div`  
  ul {
    list-style-type: none;
    padding: 20px;
    text-align: left;
    display: flex;
    @media (max-width: 768px) {
      /* Switch to column layout on mobile */
      flex-direction: column;
    }

  }

  li {
    margin-right: 20px; /* Adjust the spacing between items */
    text-align: left;
    @media (max-width:600px){
      font-size: 12px;
  }

  }

  li::before {
    content: '';
    display: inline-block;
    width: 5px; /* Adjust the size of the circle */
    height: 5px; /* Adjust the size of the circle */
    border-radius: 50%; /* Make it a full circle */
    background-color: #0B093B; /* Adjust the color of the circle */
    margin-right: 10px; /* Adjust the spacing between the circle and text */
  }
`;

export default CircleBulletList;